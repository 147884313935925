import {Link, withRouter} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import moment from "moment";
import {getGameHistory, getNewUsersReport, getUserDetailReport} from "../../reducers/tools/Report";
import {useDispatch, useSelector} from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {initConfigInputTime} from "../../services/config";
import {format} from "date-fns";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import Topup from "../../components/User/Topup";
import ChangPassword from "../../components/User/ChangePassword";
import BankAccount from "../../components/User/BankAccount";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import {UserContext} from "../../context/UserContext";
import ModalApp from "../../components/Modal/ModalApp";
import {rowRuning} from "../../services/functions";
import UserService, {getUserById} from "../../services/UserService";
import {USER_UPDATE_KEY} from "../../actions/type";

const userTemp = [];
const User = () => {


    const dispatch = useDispatch();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date(new Date().setHours(23, 59, 59, 0)));
    const [activePage, setActivePage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(15);
    const [modalComponent, setModalComponent] = useState(null);
    const [modalConent, setModalConent] = useState({
        title: '', size: '',
    });

    const usersList = useSelector((state) => {
        return state.report.usersReport || [];
    });

    const {getUser} = useContext(UserContext)
    const [Parants, setParants] = useState({})
    const [upline, setUpline] = useState({})


    useEffect(() => {
        dispatch(getNewUsersReport(moment(selectedStartDate).format('YYYY-MM-DD'), '', itemPerPage));
    }, [])

    useEffect(() => {
        if (typeof usersList.users !== `undefined`) {
            let check = []
            // usersList.users.map(user => {
            //     if (typeof user?.upline !== `undefined`) {
            //         // getUser(user?.upline[0])


            //         const getUserParent = async (id) => {
            //             if (typeof userTemp[id] === `undefined`) {
            //                 try {
            //                     const resp = await getUserById(id)
            //                     const u = resp.data
            //                     //console.log(u)
            //                     if (typeof u?.id !== `undefined`) {
            //                         setParants({...upline, [u?.id]: u.username})
            //                     }
            //                     return resp.data
            //                 } catch (e) {
            //                     console.log(e)
            //                 }
            //             }
            //         }
            //         if (typeof user?.upline[0] !== `undefined` && typeof upline[user?.upline[0]] === `undefined`) {
            //             getUserParent(user?.upline[0])
            //         }

            //         // const uname = resp.data
            //         // userTemp[user?.upline[0]] = uname?.username


            //     }
            //     // console.log(user)
            // })

            usersList.users.map(user => {
                if (typeof user?.upline !== `undefined`) {
                    
                    if(user?.upline != null && user?.upline.egame || user?.upline != null && user?.upline.livedealer || user?.upline != null && user?.upline.sport){
                        const egame = user?.upline.egame;
                        const livedealer = user?.upline.livedealer;
                        const sport = user?.upline.sport;

                        setUpline({...upline, ['egame']: egame,['livedealer']: livedealer ,['sport']: sport})
                        //console.log(user?.upline)
                    }else{
                        setUpline({...upline, ['egame']: null,['livedealer']: null ,['sport']: null})
                    }
                }else{
                    //console.log(user.upline)
                }
            })
        }
    }, [usersList])


    const [initDate, setInitDate] = useState({
        ...initConfigInputTime,
        startDate: moment(),
        endDate: moment(),
    })


    const handlePageSizeChange = (pageSize) => {
        setItemPerPage(pageSize)
        const start = moment(selectedStartDate).format('YYYY-MM-DD');
        dispatch(getNewUsersReport(start, '', itemPerPage));
    }

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        const start = moment(selectedStartDate).format('YYYY-MM-DD');
        dispatch(getNewUsersReport(start, '', itemPerPage, pageNumber));
    }

    function handleCallBackDateRang(start, end, label) {
        setSelectedStartDate(moment(start).format());
        setSelectedEndDate(moment(end).format());
    }

    const handleChangeDate = (date) => {
        setSelectedStartDate(date)
    }

    const onSubmit = () => {
        const start = moment(selectedStartDate).format('YYYY-MM-DD');
        dispatch(getNewUsersReport(start, '', itemPerPage));
    }

    const handleBalance = (e, id) => {
        e.preventDefault();
        dispatch(getUserDetailReport(id))
    }

    const modalRef = useRef(null)

    function onOpenModalApp(content, component) {
        if (modalRef.current) {

            setModalComponent(component)
            setModalConent({
                ...modalConent,
                title: content.title,
                size: content.size,
            })
            modalRef.current.show()
        }
    }

    const [parentNames, setParentNames] = useState([]);
  
    useEffect(() => {
        const fetchAllParentNames = async () => {
            if (usersList && usersList?.count > 0) {
                try {
                    const userss = usersList.users;

                    const promises = userss.map(async (user) => {
                        if (user.parents && user.parents[0]) {
                            const resp = await getUserById(user.parents[0]);
                            return resp ?? '-';
                        }
                        return '-';
                    });

                    const results = await Promise.all(promises);

                    console.log(results)
                    setParentNames(results);
                } catch (error) {
                    console.error(error);
                    setParentNames(usersList.map(() => '-')); // ตั้งค่าเป็น '-' ถ้าล้มเหลว
                }
            }
        };

        fetchAllParentNames();
    }, [usersList]);

    return (

        <>
            <ModalApp component={modalComponent} content={modalConent} ref={modalRef}/>
            <div className="page-header page-header-light">
                <div className="page-header-content header-elements-md-inline align-items-center">
                    <div className="page-title d-flex">
                        <h4>รายงานลูกค้าใหม่</h4>
                    </div>
                </div>
                <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
                    <div className="d-flex">
                        <div className="breadcrumb">
                            <Link to="/" className="breadcrumb-item">
                                <i className="icon-home2 mr-2"></i> หน้าแรก
                            </Link>
                            <span className="breadcrumb-item active">รายงานลูกค้าใหม่</span>
                        </div>
                        <a
                            href="/#"
                            className="header-elements-toggle text-default d-md-none"
                        >
                            <i className="icon-more"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-inline">
                                    {/*<div className="form-group mr-2">*/}
                                    {/*    <label className="pr-1">รายการต่อหน้า</label>*/}
                                    {/*    <select className="form-control form-control-sm" onChange={(e) => handlePageSizeChange(e.target.value)}>*/}
                                    {/*        <option value="15">15</option>*/}
                                    {/*        <option value="20">20</option>*/}
                                    {/*        <option value="30">30</option>*/}
                                    {/*        <option value="50">50</option>*/}
                                    {/*        <option value="100">100</option>*/}
                                    {/*    </select>*/}
                                    {/*</div>*/}
                                    <div className="form-group mr-2">
                                        <DatePicker selected={selectedStartDate} onChange={e => handleChangeDate(e)} className="form-control form-control-sm"/>
                                        {/*<DateRangePicker*/}
                                        {/*    initialSettings={initDate}*/}
                                        {/*    onCallback={handleCallBackDateRang}*/}
                                        {/*>*/}
                                        {/*    <input type="text" style={{width: "164px"}} className="form-control form-control-sm"/>*/}
                                        {/*</DateRangePicker>*/}
                                    </div>
                                    <div className="form-group mr-2">
                                        <button onClick={() => onSubmit()} className="btn btn-primary btn-sm">ค้นหา</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-lg-right">
                                <div className="h4">ทั้งหมด <CurrencyFormat value={usersList.count ? usersList.count : 0} displayType={'text'} thousandSeparator={true}/> คน
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="table-responsive">
                        <table className="table table-sm table-striped table-bordered">
                            <thead>
                            <tr>
                                <th className="text-center" width={60}>ลำดับ</th>
                                <th className="text-center" width={180}>วันที่</th>
                                <th className="text-center" width={180}>ชื่อผู้ใช้</th>
                                <th className="text-center" width={180}>ชื่อ</th>
                                <th className="text-center" width={180}>Upline</th>
                                <th className="text-center" width={180}>โทร</th>
                                <th className="text-center" width={180}>คงเหลือ</th>
                                <th className="text-center" width={100}>เติม</th>
                                <th className="text-center" width={100}>ถอน</th>
                                <th className="text-center" width={130}>เปลี่ยนรหัสผ่าน</th>
                                {/*<th className="text-center" width={130}>บัญชี</th>*/}
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {usersList.users != false && typeof usersList.users != "undefined" && usersList.users.length > 0 ? usersList.users.map((user, i) => (
                                <tr key={user.id}>
                                    <td className="text-center">{rowRuning(i, activePage, itemPerPage)}</td>
                                    <td className="text-center">
                                        <Moment format="YYYY-MM-DD HH:mm">
                                            {user.createdTime}
                                        </Moment>
                                    </td>
                                    <td>{user.username}</td>
                                    <td>{user.name}</td>
                                    <td className="text-center">
                                        {parentNames[i]?.data?.username || '-'}
                                        </td>
                                    <td>{user.phone}</td>
                                    <td className="text-center">
                                        <CurrencyFormat value={user.balance} displayType={'text'} thousandSeparator={true}/>
                                        <div>
                                            <a href="" onClick={e => handleBalance(e, user.id)}><u>ตรวจสอบเครดิต</u></a>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({title: 'เติมเงิน', size: 'sm'},
                                            <Topup type="deposit" user={user}/>)} className="btn btn-success btn-sm">
                                            <i className="icon-plus2"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({title: 'ถอนเงิน', size: 'sm'},
                                            <Topup type="revoke" user={user}/>)} className="btn btn-danger btn-sm">
                                            <i className="icon-minus2"></i>
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button onClick={() => onOpenModalApp({
                                                title: 'เปลี่ยนรหัสผ่าน : ' + user.username,
                                                size: 'sm'
                                            },
                                            <ChangPassword user={user}/>)} className="btn btn-primary btn-sm">
                                            <i className="icon-key"></i>
                                        </button>
                                    </td>
                                    {/*<td className="text-center">*/}
                                    {/*    <button onClick={() => onOpenModalApp({*/}
                                    {/*            title: 'บัญชีธนาคาร : ' + user.username,*/}
                                    {/*            size: 'lg'*/}
                                    {/*        },*/}
                                    {/*        <BankAccount handlePageChange={handlePageChange} user={user}/>)} className="btn btn-warning btn-sm">*/}
                                    {/*        <i className="icon-file-check"></i>*/}
                                    {/*    </button>*/}
                                    {/*</td>*/}

                                    <td></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={20} className="text-center">ไม่พบข้อมูล</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={usersList?.pageSize}
                    totalItemsCount={usersList.count ? usersList.count : 0}
                    pageRangeDisplayed={5}
                    onChange={e => handlePageChange(e)}
                />
            </div>
        </>
    )
}

export default withRouter(User)
