import {createSlice} from "@reduxjs/toolkit";
import {NotificationManager} from "react-light-notifications";

import {
    ApiQuotaBankOfAgent,
    ApiDepostBankOfAgent,
    ApiWithdrawBankOfAgent,
    DepostBankOfAgent,
    WithdrawBankOfAgent,
    EnableBankDepositPUT,
    DisableBankDepositDELETE,

    EnableBankWithdrawPUT,
    DisableBankWithdrawDELETE, ApiGetBankBalance
} from "../../api/agent";
import MobileAppSlice from "./MobileApp";

const AgentBankSlice = createSlice({
    name: "AgentBankSlice",
    initialState: {
        currentPrefix: "",
        bankList: [
            {name: "ธนาคารกสิกรไทย", code: "KBANK"},
            {name: "ธนาคารกรุงเทพ", code: "BBL"},
            {name: "ธนาคารกรุงไทย", code: "KTB"},
            {name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", code: "BAAC"},
            // {name: "ธนาคารทหารไทย", code: "TMB"},
            {name: "ธนาคารทหารไทยธนชาต", code: "TTB"},
            {name: "ธนาคารไอซีบีซี (ไทย)", code: "ICBC"},
            {name: "ธนาคารไทยเครดิตเพื่อรายย่อย", code: "TCD"},
            {name: "ธนาคารซิตี้แบงก์", code: "CITI"},
            {name: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)", code: "SCBT"},
            {name: "ธนาคารซีไอเอ็มบีไทย", code: "CIMB"},
            {name: "ธนาคารยูโอบี", code: "UOBT"},
            {name: "ธนาคารกรุงศรีอยุธยา", code: "BAY"},
            {name: "ธนาคารออมสิน", code: "GSB"},
            {name: "ธนาคารเอชเอสบีซี ประเทศไทย", code: "HSBC"},
            {name: "ธนาคารมิซูโฮ คอร์ปอเรต", code: "MIZUHO"},
            {name: "ธนาคารอาคารสงเคราะห์", code: "GHB"},
            {name: "ธนาคารแลนด์ แอนด์ เฮ้าส์", code: "LHBANK"},
            // {name: "ธนาคารธนชาต", code: "TBANK"},
            {name: "ธนาคารทิสโก้", code: "TISCO"},
            {name: "ธนาคารเกียรตินาคิน", code: "KKP"},
            {name: "ธนาคารไทยพาณิชย์", code: "SCB"},
            {name: "ธนาคารอิสลามแห่งประเทศไทย", code: "IBANK"},
            {name: "BCEL One ທະນາຄານ ການຄ້າຕ່າງປະເທດລາວ ມະຫາຊົນ", code: "COEBLALA"},
            {name: "ACLEDA ທະນາຄານ ACLEDA", code: "ACLEDA"},
            {name: "APB ທະນະຄານສົ່ງເສີມກະສິກຳ", code: "APB"},
            {name: "BIC ທະນະຄານ BIC(ລາວ)", code: "BIC"},
            {name: "ICBC ທະນະຄານ ICBC(ລາວ)", code: "ICBCLAO"},
            {name: "JDB ທະນະຄານຮ່ວມພັດທະນາ", code: "JDB"},
            {name: "LAOVIET ທະນະຄານລາວ-ຫວຽດ", code: "LAOVIET"},
            {name: "LDB ທະນະຄານພັດທະນາລາວ", code: "LDB"},
            {name: "Maruhan Japan ທະນະຄານ Maruhan Japan", code: "MaruhanJapan"},
            {name: "Sacombank ທະນະຄານ Sacombank", code: "Sacombank"},
            {name: "ST Bank ທະນະຄານ ST Bank", code: "STBank"},
            {name: "Vietin Bank ທະນະຄານ Vietin Bank", code: "VietinBank"},
        ],
        depositBanks: [],
        withdrawBanks: [],
        allBankDW: [],
    },
    reducers: {
        getBankAgent: (state, action) => {
            const {data} = action.payload;
            return Object.assign(state, {
                bankList: data,
            });
        },
        getDepositBankAgent: (state, action) => {
            const {data} = action.payload;
            data.map((i) => Object.assign(i, {type: "deposit"}));

            return Object.assign(state, {
                depositBanks: data,
                allBankDW: [...state.withdrawBanks].concat(data),
            });
        },
        getWithdrawBankAgent: (state, action) => {
            const {data} = action.payload;
            data.map((i) => Object.assign(i, {type: "withdraw"}));

            return Object.assign(state, {
                withdrawBanks: data,
                allBankDW: [...state.depositBanks].concat(data),
            });
        },
        currentPrefix: (state, action) => {
            const {prefix} = action.payload;
            return Object.assign(state, {
                currentPrefix: prefix,
            });
        },
        setBankBalance: (state, {payload}) => {
            return {
                ...state,
                withdrawBanks: state.withdrawBanks.map(v => v.id == payload.id ? {...v, balance: payload.balance} : v)
            }
        }

    },
});
export default AgentBankSlice;

export const QuotaBankOfAgent = (scbAcc, prefix, handleClose) => {
    return (dispatch) => {
        return ApiQuotaBankOfAgent(scbAcc, prefix)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Update quota bank success",
                    });

                    handleClose();
                }
            })
            .catch((e) => {
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const AddDepositBankOfAgent = (model, handleClose) => {
    return (dispatch) => {
        return DepostBankOfAgent(model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Added bank success",
                    });

                    dispatch(fetchDepositBankOfAgent());

                    handleClose();
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const AddWithdrawBankOfAgent = (model, handleClose) => {
    return (dispatch) => {
        return WithdrawBankOfAgent(model)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Withdraw bank success",
                    });

                    dispatch(fetchWithdrawBankOfAgent());

                    handleClose();
                }
            })
            .catch((e) => {
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const fetchDepositBankOfAgent = () => {
    return (dispatch) => {
        return ApiDepostBankOfAgent()
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(
                        AgentBankSlice.actions.getDepositBankAgent({data: items.data})
                    );
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const fetchWithdrawBankOfAgent = () => {
    return (dispatch) => {
        return ApiWithdrawBankOfAgent()
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(
                        AgentBankSlice.actions.getWithdrawBankAgent({data: items.data})
                    );
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const DisableBankAgentDeposit = (depositId) => {
    return (dispatch) => {
        return DisableBankDepositDELETE(depositId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const EnableBankDepositAgent = (depositId) => {
    return (dispatch) => {
        return EnableBankDepositPUT(depositId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const DisableBankAgentWithdraw = (withdrawId) => {
    return (dispatch) => {
        return DisableBankWithdrawDELETE(withdrawId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};

export const EnableBankWithdrawAgent = (withdrawId) => {
    return (dispatch) => {
        return EnableBankWithdrawPUT(withdrawId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    dispatch(fetchDepositBankOfAgent());
                    dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};
export const GetBankBalance = (appId) => {
    return (dispatch) => {
        return ApiGetBankBalance(appId)
            .then((response) => {
                if (!response.status >= 400) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then((response) => response)
            .then((items) => {
                if (items.status === 200) {
                    NotificationManager.success({
                        title: "Success",
                        message: "Check Balance!",
                    });
                    // dispatch(fetchDepositBankOfAgent());
                    dispatch(MobileAppSlice.actions.setBankBalance({id: appId, balance: items?.data?.balance ?? 0}))
                    // dispatch(fetchWithdrawBankOfAgent());
                }
            })
            .catch((e) => {
                console.log(e);
                NotificationManager.error({
                    title: "Error",
                    message: "Some ploblem!",
                });
            });
    };
};
